import React from 'react'
import Banner from './Banner'
import Header from './Header'

const PackageHeader = () => {
    return (
        <div>
            <Banner />
            <Header />
        </div>
    )
}

export default PackageHeader